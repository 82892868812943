export const formatCPF = (rawValue:string, noReplace = false) => {
  const cleaned = noReplace ? (`${rawValue}`) : `${rawValue}`.replace(/\D/g, '');
  const first = cleaned.substring(0, 3);
  const second = cleaned.substring(3, 6);
  const third = cleaned.substring(6, 9);
  const fourth = cleaned.substring(9, 11);
  return `${first}${second ? `.${second}` : ''}${third ? `.${third}` : ''}${fourth ? `-${fourth}` : ''}`;
};

export const formatCNPJ = (rawValue:string) => {
  const cleaned = (`${rawValue}`).replace(/\D/g, '');
  const first = cleaned.substring(0, 2);
  const second = cleaned.substring(2, 5);
  const third = cleaned.substring(5, 8);
  const fourth = cleaned.substring(8, 12);
  const fifth = cleaned.substring(12, 14);

  return `${first ? `${first}` : ''}${second ? `.${second}` : ''}${third ? `.${third}` : ''}${fourth ? `/${fourth}` : ''}${fifth ? `-${fifth}` : ''}`;
};

export const formatPhoneNumber = (rawValue:string, includeCountryCode = true) => {
  let cleaned = (`${rawValue}`).replace(/\D/g, '');
  const startsWithCountryCode = includeCountryCode && (rawValue.startsWith('+') || cleaned.length === 13);
  const countryCode = startsWithCountryCode ? `+${cleaned.substring(0, 2)} ` : '';
  if (startsWithCountryCode) {
    cleaned = cleaned.slice(2);
  }

  const first = cleaned.substring(0, 2);
  const thirdIndex = cleaned.length > 10 ? 7 : 6;
  const second = cleaned.substring(2, thirdIndex);
  const third = cleaned.substring(thirdIndex, 11);

  const formatted = `${countryCode}${first ? `(${first}` : ''}${second ? `) ${second}` : ''}${third ? `-${third}` : ''}`;
  return formatted;
};

export const formatPhoneNumberSplit = (rawValue:string) => {
  let cleaned = (`${rawValue}`).replace(/\D/g, '');
  const startsWithCountryCode = rawValue.startsWith('+') || cleaned.length === 13;
  const countryCode = startsWithCountryCode ? `+${cleaned.substring(0, 2)} ` : null;
  if (startsWithCountryCode) {
    cleaned = cleaned.slice(2);
  }

  const first = cleaned.substring(0, 2);
  const thirdIndex = cleaned.length > 10 ? 7 : 6;
  const second = cleaned.substring(2, thirdIndex);
  const third = cleaned.substring(thirdIndex, 11);
  const phoneNumber = `${first ? `(${first}` : ''}${second ? `) ${second}` : ''}${third ? `-${third}` : ''}`;

  return {
    phoneNumber,
    countryCode,
  };
};

export function validatePhoneNumber(rawValue: string) {
  const cleaned = (`${rawValue}`).replace(/\D/g, '');
  return cleaned.length >= 11;
}

export function validateCPF(rawValue: string) {
  const treatedCpf = rawValue.replace(/\D/g, '');
  const numericalCpf = treatedCpf.split('').map((digit) => parseInt(digit, 10));
  const [firstVerifyingDigit, secondVerifyingDigit] = numericalCpf.slice(9, 11);

  const factorsForFirstVerifyingDigit = [10, 9, 8, 7, 6, 5, 4, 3, 2] as const;
  const firstVerifyingSum = numericalCpf
    .slice(0, 9)
    .map((value, index) => value * factorsForFirstVerifyingDigit[index]!)
    .reduce((acc, value) => acc + value);
  const firstVerifyingDigitRemainder = firstVerifyingSum % 11;
  if (
    (firstVerifyingDigitRemainder < 2 && firstVerifyingDigit !== 0)
    || (firstVerifyingDigitRemainder >= 2 && firstVerifyingDigit !== (11 - firstVerifyingDigitRemainder))
  ) {
    return false;
  }

  const factorsForSecondVerifyingDigit = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2] as const;
  const secondVerifyingSum = numericalCpf
    .slice(0, 10)
    .map((value, index) => value * factorsForSecondVerifyingDigit[index]!)
    .reduce((acc, value) => acc + value);
  const secondVerifyingDigitRemainder = secondVerifyingSum % 11;

  if (
    (secondVerifyingDigitRemainder < 2 && secondVerifyingDigit !== 0)
    || (secondVerifyingDigitRemainder >= 2 && secondVerifyingDigit !== 11 - secondVerifyingDigitRemainder)
  ) {
    return false;
  }

  return true;
}

export function validateCNPJ(value: string) {
  // Remove non-digit characters
  const cnpj = value.replace(/[^\d]+/g, '');

  // Check if the CNPJ length is 14
  if (cnpj.length !== 14) return false;

  // Invalid known CNPJs
  if (/^(\d)\1*$/.test(cnpj)) return false;

  // Validate verification digits
  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  const digits = cnpj.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i -= 1) {
    sum += parseInt(numbers.charAt(length - i), 10) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) return false;

  length += 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;
  for (let i = length; i >= 1; i -= 1) {
    sum += parseInt(numbers.charAt(length - i), 10) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) return false;

  return true;
}

export const formatCurrencyReal = (value: string|number, minimumFractionDigits: number) => {
  if (!value && value !== 0) return value;
  return parseFloat(String(value))?.toLocaleString('pt-BR', {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });
};

interface PasswordHint {
  upper: boolean,
  lower: boolean,
  ten: boolean,
  special: boolean,
}

export function validatePassword(password:string, setPasswordHint:(hint:PasswordHint)=>void) {
  const passwordHint = {
    upper: /[A-Z]/.test(password),
    lower: /[a-z]/.test(password),
    ten: password.length >= 10,
    special: /[(!@#$%^&*)]/.test(password),
  };
  setPasswordHint(passwordHint);

  if (Object.values(passwordHint).every((value) => value)) {
    return true;
  }
  return false;
}

export function validatePixKey(key:string) {
  // these rules are ported from the otmow backend custom code
  if (key.includes('@')) { return true; }
  if ([10, 11, 14].includes(key.length)) { return true; }
  if (key.length === 36 && key.match(/-/g)?.length === 4) { return true; }
  return false;
}
