import { cls } from '@repo/common/utils';
import { cva, type VariantProps } from 'class-variance-authority';

export const buttonVariants = cva(
  'font-semibold rounded-lg transition-colors disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-blue-600 text-white hover:bg-blue-500',
        outline: 'bg-transparent border border-solid border-black text-black hover:bg-black/5',
      },
      size: {
        default: 'h-10 px-3 py-1.5 text-sm',
        lg: 'h-12 px-4 py-2 text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface HomeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}

export default function HomeButton({
  children, className, variant, size, ...props
}: HomeButtonProps) {
  return (
    <button
      type="button"
      className={cls(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {children}
    </button>
  );
}
