'use client';

import { usePostHog } from 'posthog-js/react';
import { createContext, useContext, useEffect, useState, PropsWithChildren } from 'react';

const FeatureFlagContext = createContext<Record<string, string | boolean>>({});

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  const posthog = usePostHog();
  const [flags, setFlags] = useState<Record<string, string | boolean>>({});

  useEffect(() => {
    const unsubscribe = posthog.onFeatureFlags((_, variants) => {
      setFlags(variants);
    });

    return () => {
      unsubscribe();
    };
  }, [posthog]);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export function useFeatureFlag(name: string): boolean | string {
  const featureFlags = useContext(FeatureFlagContext);
  return featureFlags[name] ?? false;
}
