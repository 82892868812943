'use client';

import Link from 'next/link';
import React from 'react';
import { useFeatureFlag } from '@/lib/FeatureFlagProvider';

export default function BlogLink({ children }: React.PropsWithChildren) {
  const blogEnabled = useFeatureFlag('blog');

  if (!blogEnabled) {
    return null;
  }

  return (
    <Link href="/blog">
      {children}
    </Link>
  );
}
